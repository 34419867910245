// src/components/Contact.js
import React from 'react';
//import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-page">
      <h2>Contact Us</h2>
      <p>If you have any questions, please reach out to us at:</p>
      <p>Email: </p>
      <p>Phone: </p>
    </div>
  );
};

export default Contact;
