import React, { useState, useEffect } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { BsZoomIn, BsZoomOut } from 'react-icons/bs';
import './MindMaps.css';

const MindMapGenerator = ({ onMindMapGenerated }) => {
  const [userEmail, setUserEmail] = useState('');
  const [filenames, setFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [mindMapImages, setMindMapImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [zoomLevels, setZoomLevels] = useState({}); // Update to handle zoom levels by title

  useEffect(() => {
    const savedCredentials = localStorage.getItem('rx_chatbot_credentials');
    if (savedCredentials) {
      const { email } = JSON.parse(savedCredentials);
      setUserEmail(email);
    }
  }, []);

  useEffect(() => {
    const fetchFilenames = async () => {
      if (userEmail) {
        try {
          const response = await fetch(`/api/get-filenames?email=${encodeURIComponent(userEmail)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
          const data = await response.json();
          setFilenames(data.filenames);
        } catch (error) {
          console.error('Error fetching filenames:', error);
        }
      }
    };

    fetchFilenames();
  }, [userEmail]);

  useEffect(() => {
    const handleLoginWithStoredCredentials = async () => {
      try {
        const storedCredentials = localStorage.getItem('rx_chatbot_credentials');
        const storedSubscriptionStatus = localStorage.getItem('isSubscribed');

        if (storedSubscriptionStatus) {
          setIsSubscribed(JSON.parse(storedSubscriptionStatus));
        }
        if (!storedCredentials) {
          throw new Error('No stored credentials found');
        }
        const { email, password } = JSON.parse(storedCredentials);

        const loginResponse = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        if (loginResponse.ok) {
          const loginData = await loginResponse.json();
          console.log('Logged in successfully:', loginData);
          setIsLoggedIn(true);
          setUserEmail(email);

          const filenamesResponse = await fetch(`/api/get-filenames?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
          const filenamesData = await filenamesResponse.json();
          setFilenames(filenamesData.filenames || []);
        } else {
          throw new Error('Failed to login with stored credentials');
        }
      } catch (error) {
        console.error('Error logging in with stored credentials:', error);
      }
    };

    handleLoginWithStoredCredentials();
  }, []);

  const generateMindMap = async () => {
    if (selectedFile) {
      setLoading(true);
      try {
        const response = await fetch('/api/generate-mind-map', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ filename: selectedFile }),
        });
        const data = await response.json();
        const bytes_dict = data.mind_map_image_bytes;
        console.log('Mind Map Data:', bytes_dict);

        if (bytes_dict && typeof bytes_dict === 'object') {
          const imageUrls = Object.entries(bytes_dict).map(([title, imageBytes]) => ({
            title,
            url: `data:image/png;base64,${imageBytes}`
          }));

          setMindMapImages(imageUrls);
          setZoomLevels(imageUrls.reduce((acc, { title }) => {
            acc[title] = 1;
            return acc;
          }, {}));
          
          onMindMapGenerated(imageUrls);
        } else {
          console.error('Failed to fetch mind map:', bytes_dict);
          setMindMapImages([]);
        }
      } catch (error) {
        console.error('Error generating mind map:', error);
        setMindMapImages([]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSaveMindMap = (imageUrl, title) => {
    if (imageUrl) {
      const link = document.createElement('a');
      link.href = imageUrl;
      link.setAttribute('download', `${title}.png`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Mind map image is empty.');
    }
  };

  const handleZoomIn = (title) => {
    setZoomLevels((prevZoomLevels) => ({
      ...prevZoomLevels,
      [title]: (prevZoomLevels[title] || 1) + 0.1
    }));
  };

  const handleZoomOut = (title) => {
    setZoomLevels((prevZoomLevels) => ({
      ...prevZoomLevels,
      [title]: Math.max(0.1, (prevZoomLevels[title] || 1) - 0.1)
    }));
  };

  return (
    <div className="MindMapGenerator-container">
      <h1 className="MindMapGenerator-title">Mind Map <span>Generator</span></h1>
      {isLoggedIn && isSubscribed ? (
        <div className="MindMapGenerator-form">
          <label>Select Document</label>
          <select
            id="filename"
            name="filename"
            className="MindMapGenerator-select"
            value={selectedFile}
            onChange={(e) => setSelectedFile(e.target.value)}
          >
            <option value="">Select a file</option>
            {filenames.map((filename, index) => (
              <option key={index} value={filename}>{filename}</option>
            ))}
          </select>
          <button 
            className="MindMapGenerator-button" 
            onClick={generateMindMap}
            disabled={loading}
          >
            {loading ? 'Generating Mind Map...' : 'Generate Mind Map'}
          </button>
        </div>
      ) : (
        <p>Please login and subscribe to generate Mind Maps.</p>
      )}
      {mindMapImages.length > 0 && (
        <div className="MindMapGenerator-images-container">
          {mindMapImages.map(({ title, url }) => (
            <div key={title} className="MindMapGenerator-image-wrapper">
              <div className="MindMapGenerator-zoom-controls">
                <button onClick={() => handleZoomIn(title)}><BsZoomIn /></button>
                <button onClick={() => handleZoomOut(title)}><BsZoomOut /></button>
                <button onClick={() => handleSaveMindMap(url, title)}>Download</button>
              </div>
              <TransformWrapper>
                <TransformComponent>
                  <img 
                    src={url} 
                    alt={`Generated Mind Map ${title}`} 
                    className="MindMapGenerator-image"
                    style={{ transform: `scale(${zoomLevels[title] || 1})` }}
                  />
                </TransformComponent>
              </TransformWrapper>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

MindMapGenerator.defaultProps = {
  onMindMapGenerated: () => {},
};

export default MindMapGenerator;
