import React, { useState, useEffect } from 'react';
import './QuestionsGenerator.css';

const QuestionsGenerator = () => {
  const [userEmail, setUserEmail] = useState('');
  const [filenames, setFilenames] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [quiz, setQuiz] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [showPopup, setShowPopup] = useState(false);
  const [title, setTitle] = useState('');
  const [tags, setTags] = useState('');
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    const savedCredentials = localStorage.getItem('rx_chatbot_credentials');
    if (savedCredentials) {
      const { email } = JSON.parse(savedCredentials);
      setUserEmail(email);
    }
  }, []);

  useEffect(() => {
    const fetchFilenames = async () => {
      if (userEmail) {
        try {
          const response = await fetch(`/api/get-filenames?email=${encodeURIComponent(userEmail)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
          const data = await response.json();
          setFilenames(data.filenames);
        } catch (error) {
          console.error('Error fetching filenames:', error);
        }
      }
    };

    fetchFilenames();
  }, [userEmail]);

  useEffect(() => {
    const handleLoginWithStoredCredentials = async () => {
      try {
        const storedCredentials = localStorage.getItem('rx_chatbot_credentials');
        const storedSubscriptionStatus = localStorage.getItem('isSubscribed');

        if (storedSubscriptionStatus) {
          setIsSubscribed(JSON.parse(storedSubscriptionStatus));
        }
        if (!storedCredentials) {
          throw new Error('No stored credentials found');
        }
        const { email, password } = JSON.parse(storedCredentials);

        const loginResponse = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, password }),
        });

        if (loginResponse.ok) {
          const loginData = await loginResponse.json();
          console.log('Logged in successfully:', loginData);
          setIsLoggedIn(true);
          setUserEmail(email);

          // Fetch filenames if login is successful
          const filenamesResponse = await fetch(`/api/get-filenames?email=${encodeURIComponent(email)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
          const filenamesData = await filenamesResponse.json();
          setFilenames(filenamesData.filenames || []);
        } else {
          throw new Error('Failed to login with stored credentials');
        }
      } catch (error) {
        console.error('Error logging in with stored credentials:', error);
        // Optionally handle login failure (e.g., clear local storage)
        // localStorage.removeItem('rx_chatbot_credentials');
      }
    };

    // Attempt login with stored credentials when component mounts
    handleLoginWithStoredCredentials();
  }, []);


  const generateQuiz = async () => {
    if (selectedFile) {
      setLoading(true);
      try {
        const response = await fetch('/api/get-quiz', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ filename: selectedFile }),
        });
        const data = await response.json();
        setQuiz(data.quiz);
      } catch (error) {
        console.error('Error generating quiz:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOptionClick = (questionIndex, option) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionIndex]: option,
    }));
  };

  const handleSaveQuestion = (questionIndex) => {
    setShowPopup(true);
    setSelectedQuestionIndex(questionIndex);
    const correctAnswer = quiz[questionIndex].answer;
    setCorrectAnswers({ [questionIndex]: correctAnswer });
  };

  const handlePopupSave = async () => {
    try {
      if (selectedQuestionIndex !== null) {
        const selectedQuestion = quiz[selectedQuestionIndex];
        const questionData = {
          text: `${selectedQuestionIndex + 1}. ${selectedQuestion.question} Answer: ${correctAnswers[selectedQuestionIndex]}`,
          title: title.trim(),
          tags: tags.trim().split(",").map(tag => tag.trim()),
          type: 'question',
          email: userEmail,
        };

        const response = await fetch('/api/save', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(questionData),
        });

        if (!response.ok) {
          throw new Error('Failed to save question.');
        }

        console.log('Question saved successfully!');
        setShowPopup(false);
      } else {
        console.error('No question selected to save.');
      }
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  return (
    <div className="QuestionsGenerator-container">
      <h1 className="QuestionsGenerator-title">Quiz <span>Generator</span></h1>
      {isLoggedIn && isSubscribed ? (
      <div className="QuestionsGenerator-form">
        <label style={{marginRight: '10px'}}>Select Document</label>
        <select
          id="filename"
          name="filename"
          className="QuestionsGenerator-select"
          value={selectedFile}
          onChange={(e) => setSelectedFile(e.target.value)}
        >
          <option value="">Select a file</option>
          {filenames.map((filename, index) => (
            <option key={index} value={filename}>{filename}</option>
          ))}
        </select>
        <button 
          className="QuestionsGenerator-button" 
          onClick={generateQuiz}
          disabled={loading}
        >
          {loading ? 'Generating Quiz...' : 'Generate Quiz'}
        </button>
      </div>
      ) : (
        <p>Please login and subscribe to generate quiz.</p>
      )}
      <div className="QuestionsGenerator-quiz">
        {quiz.length > 0 && quiz.map((item, index) => (
          <div key={index} className="QuestionsGenerator-quiz-item">
            <h3 className="QuestionsGenerator-question">{item.question}</h3>
            <ul className="QuestionsGenerator-options">
              {item.options.map((option, idx) => (
                <li
                  key={idx}
                  className={`QuestionsGenerator-option ${selectedAnswers[index] ? 
                    (option === item.answer ? 'correct' : 'incorrect') : ''}`}
                  onClick={() => handleOptionClick(index, option)}
                >
                  {option}
                </li>
              ))}
            </ul>
            {selectedAnswers[index] && (
              <div className="QuestionsGenerator-answer">
                <strong>Correct Answer: {item.answer}</strong>
                <br /> <br />
                Explanation: {item.explanation} {/* Show explanation */}
              </div>
            )}
            <button className="QuestionsGenerator-save-button" onClick={() => handleSaveQuestion(index)}>
              Save Question
            </button>
          </div>
        ))}
      </div>

      {/* Popup for title and tags */}
      {showPopup && (
        <div className="QuestionsGenerator-popup">
          <div className="QuestionsGenerator-popup-content">
            <h2>Enter Title and Tags</h2>
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <input
              type="text"
              placeholder="Tags (comma separated)"
              value={tags}
              onChange={(e) => setTags(e.target.value)}
            />
            <button onClick={handlePopupSave}>Save Question</button>
            <button onClick={() => setShowPopup(false)}>Cancel</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsGenerator;
